import React from 'react';
import gdprLogo from '../../images/security/gdpr.png';
import infrastructureIcon from '../../images/security/infrastructure.svg';
import productIcon from '../../images/security/product.svg';
import socBWLogo from '../../images/security/soc-bw.png';
import socBlueLogo from '../../images/security/soc.png';
import trustIcon from '../../images/security/trust.svg';
import SecurityChapter from './SecurityChapter';
import styles from './SecurityChapters.module.scss';
import SecuritySection from './SecuritySection';

interface SecurityChaptersProps {
  onSectionEnter: (id: string) => void;
  onSectionLeave: (id: string) => void;
}

const SecurityChapters: React.SFC<SecurityChaptersProps> = ({
  onSectionEnter,
  onSectionLeave,
}) => {
  return (
    <div>
      <SecurityChapter
        title="Product Security"
        titleSpacing={{ top: 1, bottom: 10 }}
        icon={productIcon}
        id="product"
      >
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Development Process"
        >
          We follow industry best practices, so security is baked right into our
          product and regular development processes – including security design
          reviews, code reviews, unit &amp; integration tests.
          <br />
          <br /> All engineers are required to know OWASP vulnerabilities and
          use libraries, frameworks, and mitigations vetted and recommended by
          the security community, such as Strict CSP.
        </SecuritySection>
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Vulnerability Scanning"
        >
          We regularly update our servers, tools, libraries, and patching
          vulnerabilities as they are discovered. Our application, host, and
          network are automatically scanned. We also automatically detect
          out-of-date dependencies.
        </SecuritySection>
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Admin Controls"
        >
          Team administrators can manage team-wide settings, including requiring
          single sign-on and two-factor authentication, managing integrations,
          and deactivating users.
        </SecuritySection>
      </SecurityChapter>
      <SecurityChapter
        title="Infrastructure Security"
        icon={infrastructureIcon}
        id="infrastructure"
      >
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Encryption"
        >
          Data in transit runs entirely over SSL, with an A+ from Qualys SSL
          Labs. Data at rest is encrypted with AES 128/256. All passwords are
          hashed using bcrypt and billing information is entirely managed by our
          PCI-compliant payments provider (Stripe).
        </SecuritySection>
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Secrets Management"
        >
          Secrets are stored securely and never in source code. Access to our
          infrastructure and related services requires SSH and two-factor
          authentication when possible.
        </SecuritySection>
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Monitoring and Logging"
        >
          We are committed to making Slab highly available. Our infrastructure
          runs on fault-tolerant systems and backups are made daily. We leverage
          redundant third-party providers to provide 24/7 monitoring and
          alerting of any downtime.
        </SecuritySection>
      </SecurityChapter>
      <SecurityChapter
        title="Trust and Verification"
        icon={trustIcon}
        id="trust"
      >
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Penetration Testing"
        >
          We conduct annual penetration tests on our application and
          infrastructure. These audits are conducted by respected independent
          security firms. Any issues surfaced are tracked and prioritized to
          their resolution.
        </SecuritySection>
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Compliance"
        >
          Slab is hosted on Google Cloud Platform, a leading cloud provider that
          holds rigorous industry security certifications, such as SOC 2 and ISO
          27001.
          <br />
          <br />
          Slab itself is certified under SOC 3, SOC 2 Type 2, as well as being
          fully compliant with the EU General Data Protection Regulation (GDPR).
          <div className={styles.compliance}>
            <div className={styles.badge}>
              <img src={socBWLogo} alt="SOC 3" />
              <p>
                <a href="/soc-3-2023.pdf" target="_blank">
                  SOC 3
                </a>
              </p>
            </div>
            <div className={styles.badge}>
              <img src={socBlueLogo} alt="SOC 2 Type 2" />
              <p>SOC 2 Type 2</p>
            </div>
            <div className={styles.badge}>
              <img src={gdprLogo} alt="GDPR" />
              <p>GDPR</p>
            </div>
          </div>
        </SecuritySection>
        <SecuritySection
          onEnter={onSectionEnter}
          onLeave={onSectionLeave}
          title="Security Research"
        >
          We welcome responsible security research and disclosure on our product
          and infrastructure. Potential vulnerabilities can be reported by
          emailing <a href="mailto:security@slab.com">security@slab.com</a>.
        </SecuritySection>
      </SecurityChapter>
    </div>
  );
};

export default SecurityChapters;
