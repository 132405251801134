import classNames from 'classnames';
import React from 'react';
import Card from '../ui/Card';
import Caret from '../ui/Caret';
import Heading from '../ui/Heading';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './SecurityTOC.module.scss';
import SimpleTOC from './SimpleTOC';

interface SecurityTOCProps {
  activeAnchor?: string;
}

interface SecurityTOCState {
  expanded?: boolean;
}

class SecurityTOC extends React.Component<SecurityTOCProps, SecurityTOCState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: SecurityTOCState = {
    expanded: false,
  };

  handleMenuToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { activeAnchor } = this.props;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { expanded } = this.state;

    return (
      <div className={styles.container}>
        <Card>
          <div className={styles.inner}>
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
            <div
              className={styles.titleContainer}
              onClick={this.handleMenuToggle}
              role="button"
            >
              <Text xxSmall caps muted semiBold>
                Security at Slab
              </Text>
              <div className={styles.expandButton}>
                <Caret direction={expanded ? 'up' : 'down'} />
              </div>
            </div>
            <div
              className={classNames(styles.toc, {
                [styles.expanded]: expanded,
              })}
            >
              <Spacing top={4}>
                <Spacing bottom={2}>
                  <Heading level={6} color="yellow">
                    <a href="#product">Product Security</a>
                  </Heading>
                </Spacing>
                <SimpleTOC
                  activeAnchor={activeAnchor}
                  containerSelector="#product-content"
                />
              </Spacing>
              <Spacing top={4}>
                <Spacing bottom={2}>
                  <Heading level={6} color="blue">
                    <a href="#infrastructure">Infrastructure Security</a>
                  </Heading>
                </Spacing>
                <SimpleTOC
                  activeAnchor={activeAnchor}
                  containerSelector="#infrastructure-content"
                />
              </Spacing>
              <Spacing top={4}>
                <Spacing bottom={2}>
                  <Heading level={6} color="maroon">
                    <a href="#trust">Trust &amp; Verification</a>
                  </Heading>
                </Spacing>
                <SimpleTOC
                  activeAnchor={activeAnchor}
                  containerSelector="#trust-content"
                />
              </Spacing>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default SecurityTOC;
