import React from 'react';
import infrastructureIcon from '../../images/security/infrastructure.svg';
import productIcon from '../../images/security/product.svg';
import trustIcon from '../../images/security/trust.svg';
import PageHeader from '../layout/PageHeader';
import PageSection from '../ui/PageSection';
import Row from '../ui/Row';
import Spacing from '../ui/Spacing';
import SecurityNavItem from './SecurityNavItem';

const SecurityHeader: React.SFC = () => (
  <div>
    <PageSection>
      <PageHeader
        title="Security"
        description="As a founding team of engineers, our knowledge of security and its importance to your work runs deep. Your data is always safe with Slab."
      />
    </PageSection>
    <Spacing vertical={12}>
      <PageSection>
        <Row even>
          <SecurityNavItem
            icon={productIcon}
            title="Product"
            description="We follow industry best practices to develop and delivery our product securely."
            color="yellow"
            link="#product"
          />
          <SecurityNavItem
            icon={infrastructureIcon}
            title="Infrastructure"
            description="We adhere to the highest industry standards for data security using MFA and encryption. "
            color="blue"
            link="#infrastructure"
          />
          <SecurityNavItem
            icon={trustIcon}
            title="Trust &amp; Verification"
            description="We challenge our assumptions through third-party collaborations and frameworks."
            color="maroon"
            link="#trust"
          />
        </Row>
      </PageSection>
    </Spacing>
  </div>
);

export default SecurityHeader;
