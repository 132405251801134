import React from 'react';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import SecurityChapters from './SecurityChapters';
import styles from './SecurityContent.module.scss';
import SecurityTOC from './SecurityTOC';

interface SecurityContentState {
  activeAnchors: string[];
}

class SecurityContent extends React.Component<unknown, SecurityContentState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: SecurityContentState = {
    activeAnchors: [],
  };

  handleSectionEnter = (id: string) => {
    this.setState({
      activeAnchors: [...this.state.activeAnchors, id],
    });
  };

  handleSectionLeave = (id: string) => {
    this.setState({
      activeAnchors: this.state.activeAnchors.filter((a) => a !== id),
    });
  };

  render() {
    return (
      <PageSection background="light">
        <div className={styles.row}>
          <div className={styles.tocContainer}>
            <SecurityTOC activeAnchor={this.state.activeAnchors[0]} />
          </div>
          <div className={styles.contentContainer}>
            <Spacing top={10}>
              <SecurityChapters
                onSectionEnter={this.handleSectionEnter}
                onSectionLeave={this.handleSectionLeave}
              />
            </Spacing>
          </div>
        </div>
      </PageSection>
    );
  }
}

export default SecurityContent;
