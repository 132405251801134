import React, { ReactNode } from 'react';
import styles from './IconHeader.module.scss';

interface IconHeaderProps {
  icon: string;
  children: ReactNode;
}

const IconHeader: React.SFC<IconHeaderProps> = ({ icon, children }) => (
  <div className={styles.container}>
    <img alt="" role="presentation" className={styles.icon} src={icon} />
    <div>{children}</div>
  </div>
);

export default IconHeader;
