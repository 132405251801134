import classNames from 'classnames';
import React from 'react';
import styles from './SimpleTOC.module.scss';

interface SimpleTOCItem {
  id: string;
  text?: string;
}

interface SimpleTOCState {
  items?: SimpleTOCItem[];
}

interface SimpleTOCProps {
  activeAnchor?: string;
  containerSelector: string;
}

const allTitles = (container: HTMLElement | null) => {
  if (!container) {
    return [];
  }

  const headings = container.querySelectorAll('*[id]');
  const titles: SimpleTOCItem[] = [];

  headings.forEach((heading) => {
    titles.push({
      id: heading.id,
      text: (heading as HTMLElement).innerText,
    });
  });

  return titles;
};

class SimpleTOC extends React.Component<SimpleTOCProps, SimpleTOCState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: SimpleTOCState = {
    items: undefined,
  };

  componentDidMount() {
    this.updateTitlesRetry();
  }

  updateTitlesRetry = () => {
    if (!this.updateTitles()) {
      setTimeout(this.updateTitlesRetry, 1000);
    }
  };

  updateTitles = () => {
    if (!document) {
      return false;
    }

    const { containerSelector } = this.props;
    const container = document.querySelector(containerSelector);

    if (!container) {
      return false;
    }

    const items = allTitles(container as HTMLElement);
    this.setState({ items });

    return true;
  };

  render() {
    const { activeAnchor } = this.props;
    const { items } = this.state;

    if (!items) {
      return null;
    }

    return (
      <ul className={styles.list}>
        {items.map(({ id, text }) => (
          <li
            className={classNames(styles.item, {
              [styles.active]: id === activeAnchor,
            })}
            key={id}
          >
            <a href={`#${id}`}>{text}</a>
          </li>
        ))}
      </ul>
    );
  }
}

export default SimpleTOC;
