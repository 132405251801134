import React from 'react';
import styles from './Heading.module.scss';

interface HeadingProps {
  children: React.ReactNode;
  color: 'yellow' | 'red' | 'blue' | 'maroon';
  level: number;
}

const Heading: React.SFC<HeadingProps> = ({ level, children, color }) => {
  const Component = `h${level}` as React.ElementType;

  return <Component className={styles[color]}>{children}</Component>;
};

export default Heading;
