import React from 'react';
import { AccentColors } from '../../types';
import Button from '../ui/Button';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './SecurityNavItem.module.scss';

interface SecurityNavItemProps {
  color: AccentColors;
  icon: string;
  title: string;
  description: string;
  link: string;
}

const SecurityNavItem: React.SFC<SecurityNavItemProps> = ({
  color,
  icon,
  title,
  description,
  link,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Spacing bottom={4}>
          <img alt="" role="presentation" className={styles.icon} src={icon} />
        </Spacing>
        <Spacing bottom={4}>
          <h5>{title}</h5>
        </Spacing>
        <Text small>{description}</Text>
      </div>
      <Spacing top={4}>
        <Button color={color} to={link}>
          Learn More
        </Button>
      </Spacing>
    </div>
  );
};

export default SecurityNavItem;
