import _ from 'lodash';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import SimpleSection from '../ui/SimpleSection';
import Spacing from '../ui/Spacing';

interface SecuritySectionProps {
  title: string;
  children: React.ReactNode;
  onEnter?: (id: string) => void;
  onLeave?: (id: string) => void;
}

const SecuritySection: React.SFC<SecuritySectionProps> = ({
  title,
  children,
  onEnter = _.noop,
  onLeave = _.noop,
}) => {
  const id = _.kebabCase(title);

  return (
    <Spacing bottom={8}>
      <Waypoint onEnter={() => onEnter(id)} onLeave={() => onLeave(id)}>
        <SimpleSection id={id} title={title}>
          {children}
        </SimpleSection>
      </Waypoint>
    </Spacing>
  );
};

export default SecuritySection;
