import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { AccentColors } from '../../types';
import styles from './Card.module.scss';

interface CardProps {
  hoverColor?: AccentColors;
  children: ReactNode;
}

const Card: React.SFC<CardProps> = ({ children, hoverColor }) => (
  <div className={classNames(styles.card, hoverColor && styles[hoverColor])}>
    {children}
  </div>
);

export default Card;
