import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import SecurityContent from '../components/security/SecurityContent';
import SecurityHeader from '../components/security/SecurityHeader';

const Security: React.SFC = () => (
  <MarketingLayout noFooterPadding noStickyNavbar title="Security">
    <SecurityHeader />
    <SecurityContent />
  </MarketingLayout>
);

export default Security;
