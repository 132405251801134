import React, { ReactNode } from 'react';
import IconHeader from '../ui/IconHeader';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './SecurityChapter.module.scss';

interface SecurityChapterProps {
  title: string;
  titleSpacing?: { top: number; bottom: number };
  icon: string;
  children: ReactNode;
  id: string;
}

const SecurityChapter: React.SFC<SecurityChapterProps> = ({
  id,
  title,
  titleSpacing = { top: 10, bottom: 7 },
  icon,
  children,
}) => (
  <Text darker>
    <div id={id}>
      <Spacing {...titleSpacing}>
        <IconHeader icon={icon}>
          <h3 className={styles.title}>{title}</h3>
        </IconHeader>
      </Spacing>
    </div>
    <div id={`${id}-content`}>{children}</div>
  </Text>
);

export default SecurityChapter;
