import React from 'react';
import Text from './Text';
import Spacing from './Spacing';

interface SimpleSectionProps {
  id?: string;
  title: string;
  children: React.ReactNode;
  innerRef?: React.RefObject<HTMLDivElement>;
}

const SimpleSection: React.SFC<SimpleSectionProps> = ({
  id,
  title,
  children,
  innerRef,
}) => (
  <Text darker>
    <div ref={innerRef}>
      <Spacing bottom={2}>
        <Text bold>
          <div id={id}>{title}</div>
        </Text>
      </Spacing>
      <div>{children}</div>
    </div>
  </Text>
);

export default SimpleSection;
